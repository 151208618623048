import React, { useEffect, useState } from 'react'
import { List, Typography,Button } from 'antd';
import { getAllBasicForBlogByUserPIS } from '../utils/Modulos';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ArrowLeftOutlined, UserOutlined } from '@ant-design/icons';
import { rutas, isValidToken } from '../config';
import { getToken } from '../utils/Storage';
import { isAuthorized as fetchAdmin } from '../utils/Blog';
import { decrypt } from '../utils/Crypt.js';

// const { Search } = Input;

const { Text  } = Typography;
export const NavigationBlog = () => {
    const [data, setData] = useState([]);
    const [isHash, setIsHash] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { hashNota } = useParams();

    useEffect(() => {

        handleGetModulos();
        handleIsAdmin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(hashNota !== undefined){
            setIsHash(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const handleGetModulos = async() => {
        const response = await getAllBasicForBlogByUserPIS();
        if(response.error ){
            //to do: mostrar mensaje de error
            setData([{
                id: 0,
                nombre: "Error al obtener datos"
            }]);
        }else{
            response.datos.unshift({
                id: 0,
                nombre: "Inicio"
            });
            setData(response.datos);
        }
    }

    const handleIsAdmin = async () => { 

        const isTokenInQueryParams = location.search.includes("token");


        if(isValidToken() && getToken() !== false && isTokenInQueryParams){
            const response = await fetchAdmin();
            const isAuthorized = parseInt(decrypt(response));
            if(isAuthorized){
                setIsAdmin(true);
            }
        }
    }

    const handleFilterByModulo = (recordId) => {

        if(recordId === 0){

            navigate(rutas.blog.index);
            return;
        }

        navigate(rutas.blog.indexAlt + recordId);
    }
    const handleBack = () => {
        //Verificar si tiene token
        const token = getToken();

        if(isValidToken() && token !== false){
            navigate(rutas.blog.index+"?token="+token);
        }else{
            navigate(rutas.blog.index);
        }
    }

  return (
    <>
        <div>
            {
                isAdmin &&
                <Button icon={<UserOutlined />} type='primary'  onClick={() => navigate(rutas.notas.viewAll)} style={{color: "white", width:"100%", marginBottom: 10, marginTop: 10}}>Administrar</Button>
            }
            { isHash &&
                <Button icon={<ArrowLeftOutlined />} onClick={handleBack} style={{color: "white", background:"rgb(0, 159, 151)", width:"100%", marginBottom: 10, marginTop: 10}}>Volver al inicio</Button>
            }
            {/* <Search placeholder="Buscar nota" style={{marginBottom: 10}} /> */}
            <List
            style={{
                border: "1px solid #d9d9d9",
                background: "white",
                // borderBottomLeftRadius: 30,
                // borderBottomRightRadius: 30,
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",
            }}
            header={<div style={{ width:"100%", fontWeight: "bold", height: "13px"}}>Módulos</div>}
            footer={<div></div>}
            bordered
            dataSource={data}
            renderItem={(item) => (
                <List.Item style={{background: "white", cursor: "pointer", 
                    //quitar borde de la
                    border: "none",

                 }} onClick={() => item.id === 0 ? handleBack() : handleFilterByModulo(item.id)}>
                    {item.id === 0 ? <Text type='success'>{item.nombre}</Text> : <Text>{item.nombre}</Text>}
                </List.Item>
            )}
            />
        </div>
    </>
  )
}
